# 0. Config
base_url = window.location.origin

$.ajaxSetup
  headers: { 'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content') }

$(document.body).tooltip({
  selector: '[data-toggle="tooltip"]',
  container: 'body'
})

$(document.body).on 'click', 'button[data-toggle="tooltip"]', ->
  $(this).tooltip('hide')

# Clipboard.js
new Clipboard('.clipboard')

# Moment.js
$ ->
  moment.locale('bg')

# Toastr
toastr.options = {
  'closeButton': true
  "positionClass": "toast-top-center"
}
  
# 1. FORM VALIDATIONS

# 1.1. Dashboard Login Page
$("#floristLoginForm").formValidation({
  framework: 'Bootstrap'
  live: 'disabled'
  locale: 'bg_BG'
  icon:
    valid: 'glyphicon glyphicon-ok'
    invalid: 'glyphicon glyphicon-remove'
    validating: 'glyphicon glyphicon-refresh'
  fields: {
    email: {
      validators: {
        notEmpty: {}
        emailAddress: {}
      }
    }

    password: {
      validators: {
        notEmpty: {}
        stringLength: {
          min: 6
        }
      }
    }

  }
})

# 1.2. Florist Signup
$("#floristSignupForm").formValidation({
  framework: 'Bootstrap'
  live: 'disabled'
  locale: 'bg_BG'
  icon: {
    valid: 'glyphicon glyphicon-ok'
    invalid: 'glyphicon glyphicon-remove'
    validating: 'glyphicon glyphicon-refresh'
  }
  fields: {
    name: {
      validators: {
        notEmpty: {}
      }
    }

    shop: {
      validators: {
        notEmpty: {}
        stringLength: {
          min: 3
        }
      }
    }

    email: {
      validators: {
        notEmpty: {},
        emailAddress: {}
      }
    }

    phone: {
      validators: {
        notEmpty: {}
      }
    }

    city: {
      validators: {
        notEmpty: {}
      }
    }

    address: {
      validators: {
        notEmpty: {}
      }
    }

    password: {
      validators: {
        notEmpty: {}
        stringLength: {
          min: 6
        }
      }
    }

    password_conf: {
      validators: {
        notEmpty: {}
        stringLength: {
          min: 6
        }
        identical: {
          field: 'password'
        }
      }
    }
  }
})

# 1.3. Admin Login

## 2. JQUERY API REQUESTS
# 2.1. Florist Landing Page
$('input#FloristSignupCity').on 'keyup', ->
  if $(this).val().length > 2
    $('i#citySelectIcon').hide()
    $('i#citySelectSpinner').show()
    $.ajax
      url: '/api/get_cities'
      type: 'POST'
      data:
        string: $(this).val()
      success: (res) ->
        $('i#citySelectIcon').show()
        $('i#citySelectSpinner').hide()
        $('div#cities-placeholder').html(res)
      error: (jqXHR, textStatus, errorThrown) ->
        console.log(textStatus + errorThrown)
  else
    $('i#citySelectIcon').show()
    $('i#citySelectSpinner').hide()
    $('ul#cities-list').hide()


# 2.2 Cities selection
$('#cities-placeholder').on 'click', 'li.city', ->
  $('input[name=city_id]').val($(this).data('city-id'))
  $("input[name=city]").val($(this).data('city-name'))
  $('ul#cities-list').hide()

$("input[name=city]").on 'click', ->
  #$(this).val('')
  #$("input#floristSignupCityId").val('')
  $('ul#cities-list').hide()
